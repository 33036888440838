.ck-editor-custom {
  .preview-btn-wrapper {
    width: 100%;
    height: 2rem;
    padding: 1rem;
  }

  .ck-editor__editable {
    height: 400px !important;
  }

  .parsed-html {
    padding: 1rem;
    margin: 1rem;
    border: 1px solid lightgrey;
  }
}
