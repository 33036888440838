.market-analysis {
  &--icon {
    text-align: center;
    padding: 1px;
  }
  &--body {
    padding: 1px;
    &__order {
      font-size: 11px;

      padding: 1px;
      .pair {
        padding: 1px;
        font-weight: 550;
      }

      .price {
        float: right;
        padding: 1px;
      }

      .amount {
        float: right;
        padding: 1px;
      }
    }

    &__exchange {
      font-size: 9px;
      padding: 1px;

      .exchange {        
        padding: 1px;
        text-transform: uppercase;
      }
    }
  }
}
