.custom-modal {
  .modal-dialog {
    max-width: 70%;
  }

  a,
  button {
    outline: none;
  }
}
