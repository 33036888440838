*::after,
::before {
  /* Basic Reset */
  margin: 0px;
  padding: 0px;
}

html {
  // defines what 1 rem is
  font-size: 62.5%;
}

body {
  margin: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: $color-background-primary;
}
