.icon-card {
  border: 1px solid;
  padding: 1rem;
  font-size: 15px;
  background-color: white;
  border-radius: 0rem;
  height: 8rem;

  &__icon {
    padding-left: 2.5rem;
    text-align: center;
  }

  &__body {
    padding-left: 1rem;
    width: 100%;
    &--title {
      font-size: 15px;
      font-weight: 500;
    }
    &--text {
      font-size: 15px;
      padding: 0.5rem;
    }
  }

  @media screen and (max-width: 987px) {
    margin-bottom: 1rem;
  }
}

.green {
  color: #25ac37;
}
