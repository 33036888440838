.section-title {
  font-size: $overall-font-primary;
  margin: 1rem;
  text-align: center;
  font-weight: 600;
  border-bottom: 1px solid $color-light-grey;
}

.dashboard {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100vh - #{$header-height} - #{$main-panel-bottom-pad});
}

.placeholder-admin {
  background-color: white;
  color: #2a3f4c;
  text-align: center;
  padding: 3rem;
  margin: 3rem;
  font-size: 5rem;
  width: 100%;
  height: fit-content;
}
