.orderbook {
  $parent: &;

  &__loader {
    margin: 1rem;
  }

  width: 100%;

  border: 1px solid lightgrey;
  border-radius: 1px;

  display: flex;
  overflow-x: auto;
  overflow-y: auto;

  .side-buy,
  .side-sell {
    width: 50%;
    // flex-grow: 1;
    // flex-basis: 50%;
    justify-self: center;
    max-height: 1px;

    th,
    td {
      height: 17px !important;
    }

    th {
      background-color: $color-primary;
      color: white;
    }

    td,
    th {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .highlight {
      color: $color-new-blue;
      font-weight: 550;
    }
  }

  .side-buy {
    text-align: right;
    padding-right: 0;

    &--table {
      margin-right: 0;
      right: 0;
    }
  }

  .side-sell {
    text-align: left;
    padding-left: 0;

    &--table {
      margin-left: 0;
    }
  }

  .orderbook-order {
    .marked {
      text-align: center;

      span {
        padding: 2px;
        padding-left: 4px;
        padding-right: 4px;
        background-color: $color-new-blue;
        border-radius: 50%;
        font-size: 10px;
        color: white;
      }
    }

    .noti-bell {
      cursor: pointer;

      svg {
        visibility: hidden;
        color: grey;
      }

      &:hover {
        svg {
          color: black !important;
        }
      }
    }

    background-color: white;
    width: 100%;

    &:hover {
      svg {
        visibility: visible;
      }
    }
  }

  @include BackgroundFillRight();
  @include BackgroundFillLeft();
}

.table-footer {
  width: 100%;
  text-align: right;
  float: right;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: white;
  border: 1px solid lightgrey;
  border-top: none;

  .act-btn {
    cursor: pointer;
    color: $color-new-blue;
    font-weight: 550;
  }
}

.loader {
  text-align: center;
}
