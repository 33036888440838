.custom-header {
  top: 0;
  position: sticky;
  z-index: 2;
  font-size: 1.5rem;
  border-bottom: 1px solid lightgrey;
  background-color: white;
  height: $header-height;

  .navbar-brand {
    font-size: 2rem;
    width: $sidebar-width;
    margin: 0;
    outline: none;
  }

  &__toggle,
  &__toggle:focus,
  &__toggle:active,
  &__toogle:hover,
  &__toogle::after,
  &__toogle::before {
    background-color: white !important;
    color: black !important;
    box-shadow: none;
    outline: none;
    border-color: black;
  }

  &__act-btn {
    @extend .u-float-r;
    position: fixed;
    right: 1rem;
  }
}
