.custom-sidebar {
  float: left;
  height: calc(100vh - #{$header-height});
  z-index: 2;
  width: calc(#{$sidebar-width} + 65px);
  background-color: $color-primary;
  transition: width $sidebar-transition;
  overflow-y: auto;
  padding-bottom: 1.5rem;

  a {
    outline: none;
  }

  .custom-sidebar__element {
    height: $sidebar-element-hgt;
    padding: 0.1rem 0.5rem;
    border-left: 0.5rem solid transparent;
    color: white;
    cursor: pointer;
    outline: none;

    &--icon,
    &--text {
      display: inline-block;
    }

    &--icon {
      width: 10%;
      padding: 0.5rem;
    }

    &--text {
      font-size: $overall-font-secondary;
      margin-left: 1rem;
      padding: 0.5rem;
    }
    &--inactive {
      border-width: 1px 0px 1px 0px;
      border-style: solid;
      border-color: #808080;
      padding: 0px;
      text-align: left;
      cursor: auto;

      .custom-sidebar__element--text {
        font-size: 17px;
        margin-left: 0px;
        padding: 0px;
        height: 33px;
        color: #eef2f5;
        padding: 2px 8px;
        width: 100%;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .active-opt {
    border-left: 0.5rem solid $color-light-blue;
  }

  .accordion-body {
    color: white;
    margin-left: 2rem;

    ol {
      list-style-type: none;
      padding-left: 0;
      margin-bottom: 0;

      li {
        margin-left: 3px;
        margin-right: 3px;
        padding: 5px;
        text-align: left;
        background-color: $color-primary-light;
        border-radius: 2px;
      }
    }

    a {
      text-decoration: none;
      color: white;
      display: block;
      outline: none;
    }
  }

  .dropdown-menu {
    ol {
      list-style-type: none;
      padding-left: 0;
      margin-bottom: 0;

      li {
        padding: 5px;
        text-align: left;
        background-color: $color-primary-light;
      }
    }

    a {
      text-decoration: none;
      color: white;
      display: block;
    }

    padding: 0;
    margin: 0;

    .dropdown-item {
      padding: 0;
    }
  }
}

.custom-sidebar__collapsed {
  width: calc(#{$sidebar-width-collapsed} + 5px) !important;

  .custom-sidebar__element--icon {
    svg {
      margin-left: 10px;
    }
  }
}