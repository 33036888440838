.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;

  .card {
    width: 40%;

    @media screen and (max-width: $md-width) {
      width: 80%;
    }
  }
}
