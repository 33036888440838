
.header {
  background-color: #2980b9;
  padding: 20px;
  text-align: center;
}
container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.header h1 {
  color: #fff;
  margin: 0;
}
.content {
  flex: 1;
  display: flex;
}

.logs {
  flex: 1;
  background-color: #fff;
  /* padding: 20px; */
  overflow-y: auto;
}

.logger-accordion {
  background-color: #fff;
  color: #2980b9;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  transition: background-color 0.3s ease;
  position: relative;
}

.logger-accordion-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: lightblue; */
  color: blue;
  padding: 0px;
  cursor: pointer;
  display: block;
}

.logger-accordion:hover {
  background-color: #f7f7f7;
}

.logger-accordion:after {
  content: '\25B6';
  font-size: 0.8em;
  position: absolute;
  top: 10px;
  right: 10px;
  /* transform: translateY(-50%); */
  transition: transform 0.3s ease;
}

.logger-accordion.active:after {
  content: '\25BC';
}

.logger-accordion-content {
  /* display: none; */
  padding: 10px;
  /* overflow-y: auto; */
}

.logger-accordion-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 200px !important;
  overflow: auto;
}

.logger-accordion-content ul li {
  padding: 5px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logger-accordion-content ul li:hover {
  background-color: #f7f7f7;
}

.logger-accordion-separator {
  margin-bottom: 0px;
  border-bottom: 1px solid #ddd;
}

.key-values {
  margin-top: 20px;
}

.key {
  font-weight: bold;
}

.value {
  margin-left: 10px;
}



.toggle-icon {
  transition: transform 0.3s ease-in-out;
}

.logger-ui {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.logger-ui li {
  margin-bottom: 10px;
}

.logger-ui li a {
  font-size: 16px !important;
  width: 245px !important;
}
