.alerts {
  min-width: 100%;
  border: 1px solid lightgrey;

  .act-btn {
    margin-top:3px;
  }

  .utility {
    text-align: right;
  }

  table {
    width: 100%;
  }

  .table-title {
    border-bottom: 1px solid lightgrey;
  }

  table {

    background-color: white;

    tr {
      border-bottom: 1px solid lightgrey;

      &:last-child {
        border-bottom: none;
      }
    }

    th {
      background-color: $color-primary;
      color: white;
    }

    .table-footer {
      padding: 0.5rem;
    }

    td,
    th {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}
