.banner {
  &__title {
    text-align: center;
    color: $color-primary;
  }

  &__hero {
    text-align: center;
    img {
      width: 100%;
    }
  }

  &__content {
    padding: 0.5rem;
    text-align: center;
    color: $color-primary;

    ul {
      li {
        margin: 1rem 0 1rem 0;
      }
    }
  }

  &__footer {
    padding: 2rem;
    color: $color-primary;
  }
}
.section-title {
  font-size: $overall-font-primary;
  font-weight: 500;
  text-align: center;
  color: $color-primary;
  margin: 1rem;
  border-bottom: 1px solid $color-primary-lighter;
}
