$main-panel-bottom-pad: 1rem;

.src-app {
  display: relative;
  height: 100vh;
  width: 100%;
}

.main-panel {
  padding: 1rem;
  background-color: $color-background-primary;
  height: calc(100vh - #{$header-height} - #{$main-panel-bottom-pad});
  display: flex;
  overflow-y: auto;
}
