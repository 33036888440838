.table1 {
  background-color: white;

  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid lightgrey;
  height: fit-content;
  overflow: hidden;

  .react-bootstrap-table {
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__head {
    width: 100%;
    border: 1px solid lightgrey;
    padding: 1rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    font-size: $overall-font-primary;
    background-color: $color-primary;
    border-color: $color-primary;
    color: white;
    font-weight: 550;

    button {
      float: right;
      margin-left: 0.5rem;
    }
  }

  th,
  td {
    // border-right: none;
    // border-left: none;
    padding: 1rem;
  }

  td:last-child {
    button {
      margin-left: 0.5rem;
    }
  }

  th {
    background-color: white;
    text-align: center;
    font-size: 1.1rem;
  }

  .filter {
    display: flex;

    label {
      padding-left: 0.25rem;
    }
  }

  .highlight {
    color: $color-new-blue;
    font-weight: 550;
  }
}

.table-common {
  &__icon-text {
    padding: 0.25rem;
  }

  &__icon-header {
    svg {
      margin-left: 0.25rem;
    }
  }
}

.table-one {
  // padding: 1rem;
  justify-content: center;
  width: 95%;
  margin: 0 auto;
  margin-top: 2rem;
  height: fit-content;
  overflow-x: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  .action-btn {
    padding: 0.5rem;
    margin: 0.5rem;
  }

  .table--footer {
    padding: 1rem;
    margin: 1rem;
    padding-top: 0;
    margin-top: 0;
    font-size: $overall-font-secondary;

    .footer-btn {
      float: right;
      font-size: $overall-font-secondary;
    }

    .note {
      float: left;
    }
  }

  #search-bar-0 {
    font-size: $overall-font-secondary;
  }

  .react-bootstrap-table-page-btns-ul {
    font-size: $overall-font-secondary;
  }

  .react-bootstrap-table-pagination {
    margin-right: 0;
    margin-left: 0;
  }

  &__loader {
    padding: 1rem;
    text-align: center;

    &--text {
      padding: 0.5rem;
    }
  }

  table {
    border-collapse: separate;
    border-spacing: 0 1px;
    border: solid $color-light-grey 1px;
    border-radius: 0px;
  }

  thead {
    background-color: $color-primary;
    color: $color-white;
    font-size: $table-one-font-primary;

    th {
      border-bottom: none;
    }
  }

  tbody {
    background-color: $color-white;
    color: $color-primary;
    font-size: $table-one-font-secondary;
  }

  tr,
  td,
  th {
    padding: 1rem;
    border: none;
    width: 10rem;
    outline: none;

    button {
      font-size: $table-one-font-secondary;
    }
  }

  tr {
    border-bottom: 1px solid $color-light-grey;
  }
}

.static-table {
  th,
  tr,
  td,
  table,
  thead,
  tbody {
    border: none !important;
    font-size: 15px;
  }

  thead {
    border-bottom: 1px solid $color-primary !important;
  }
}
.fixed-table {
  table-layout: fixed;
  width: 100%;
  th, td {
    word-wrap: break-word;
  }
}
.table-width {
  td,
  th {
    width: 16rem;
  }
}

.simple-table {
  border: 1px solid grey;
  padding: 10px;
  margin: 10px;
  width: 100%;

  td,
  th,
  tr {
    border: 1px solid grey;
    padding: 10px;
  }
}
