.simple-card {
  padding: 1rem;
  margin: 1rem;

  max-width: 800px;

  &--header {
    text-align: center;
    font-weight: 550;
    font-size: $overall-font-primary;
    border-bottom: 1px solid lightgrey;
  }

  &--body {
    padding: 1rem;
    margin: 1rem;
    font-size: $overall-font-secondary;
  }
}

.stat-card-wrapper {
  display: flex;
  flex-direction: row;
  padding: 1rem;

  gap: 1rem;
}

.stat-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 200px;
  height: 100px;

  background-color: white;
  border-radius: 6px;
  border: 1px solid lightgray;

  padding: 1rem;

  .label {
    font-size: 1rem;
    text-align: left;
  }
  .stat {
    font-size: 3rem;
    text-align: right;
  }
}

.filter-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 1rem;
  border: 1px solid lightgrey;
  border-radius: 1rem;

  gap: 1rem;
  flex-wrap: wrap;

  label,
  input {
    margin-left: 5px;
    margin-right: 5px;
  }
}
