.table-form {
}

.dynamic-form {
  label {
    max-width: 100%;
  }

  .input-select{
    font-size: 15px !important;
  }
}

.custom-radio {
  height: 2rem;

  text-align: center;

  &__text,
  &__input {
    display: inline-block;

    height: 2rem;
  }

  &__text {
    margin-left: 1rem;
  }
}
