.btn-custom {
  &:link,
  :visited {
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    padding: 1.5rem 4rem;
    border-radius: 10rem;
    transition: all 0.2s;
    position: relative;
    font-size: 1.6rem;
  }

  &--white {
    background-color: $color-white;
    color: $color-grey-dark;

    &::after {
      background-color: white;
    }
  }

  &:hover {
    transform: translateY(-0.3rem);
    box-shadow: 0 1rem 2rem rgba($color-black, 0.2);

    &::after {
      transform: scaleX(1.4) scaleY(1.6);
      opacity: 0;
    }
  }

  &:active {
    transform: translate(-0.1rem);
    box-shadow: 0 0.5rem 1rem rgba($color-black, 0.2);
  }

  &::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.3s;
  }

  &--animated {
    animation: moveInBottom 0.5s ease-out 0.5s;
    animation-fill-mode: backwards;
  }
}

.tab-btn {
  font-size: 1.2rem;
  &.active {
    background-color: $color-black;
    color: $color-white;
  }
}

.std-btn {
  font-size: $overall-font-primary;
  margin: 0.5rem;
  right: 0;
}

.route-btn {
  display: inline;
  margin: 0;
  border: 0;
  padding: 0;
  cursor: pointer;
}
