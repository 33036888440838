.liquidator {

  .liquidator-config {
    .card-header {
      background-color: $color-primary;
      color: white;
      font-weight: 550;
    }
  }

  .container {
    margin: 0;
    max-width: 100%;
  }
}
