.user-summary {

  .comment-list {
    height: auto;
    overflow-y: auto;
    max-height: 40vh;
  }

	.comment {
		border: 1px solid rgba(16, 46, 46, 1);
    background-color: rgb(42 63 76);
		float: left;
    border-radius: 6px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    min-width: 100%;
	}
	.comment h4,
	.comment span,
	.darker h4,
	.darker span {
		display: inline;
    color:#fff
	}

  .comment span  {
    float: right;
  }

	.comment p,
	.darker p {
    color:#fff;
    font-size: 1.22rem;
	}
}
