.custom-input-1 {
  .row {
    padding: 0.5rem;
  }

  .render-field {
    overflow: auto;
  }

  input {
    font-size: 1.5rem;
    width: 100%;
    min-width: 100%;
    height: 35px;
    border-radius: 6px;
    border: 1px solid #ced4da;

    @extend .col-lg-9;
    @extend .col-md-9;
    @extend .col-sm-12;
  }

  .textarea-view{
    height: 70px;
    max-height: 80px;
    overflow: auto;
  }

  label {
    font-size: 1.5rem;
    white-space: nowrap;
    text-align: left;
    width: 100%;
    margin: 0 !important;
    height: 100%;
    border: none;

    @extend .col-lg-3;
    @extend .col-md-3;
    @extend .col-sm-12;

    .field-label {
      padding: 0.5rem;
    }
    
  }

  textarea {
    font-size: 1.5rem;
  }
  .custom-input-append {
    flex-basis: inherit;
    min-width: unset;
  }
}

.custom-input-2 {
  .row {
    padding: 0.5rem;
  }

  .render-field {
    overflow: auto;
  }

  input {
    font-size: 1.5rem;
    width: 100%;

    @extend .col-lg-9;
    @extend .col-md-9;
    @extend .col-sm-12;
  }

  label {
    font-size: 1.5rem;

    text-align: left;
    width: 100%;
    margin: 0 !important;
    height: 100%;
    border: none;
    max-width: 100%;

    .field-label {
      padding: 0.5rem;
    }
  }
}

.input-dynamic-form {
  font-size: 1.5rem;
  padding: 1rem;

  .form-control {
    font-size: 1.5rem;
  }

  &__input-radio {
    display: flex;
    flex-direction: column;
    
    input[type="radio"] {
      margin-right: 0.2rem;
      max-width: 2rem;
      width: 2rem;
    }
  }

  
}



.expandable-qr-code {
  .input-view {
    display :flex;
    border: 1px solid lightgrey;
    padding: 2px;

    &__label {
      flex-grow: 1;
      padding: 2px;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    &__btn {

    }

  }
}