.general-object-viewer {
  .accordion {
    display: block;

    .card-header {
      span {
        margin-left: 1rem;
      }
    }
  }
}