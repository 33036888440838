.custom-card-1 {
  padding: 1rem;
  margin: 1rem;
  border-radius: 1rem;


  &-body {
    padding: 2rem;
  }

  .title {
    font-size: 2rem;
    color: $color-primary;
    text-align: left;
    padding: 1rem;
    margin: 1rem 1rem 0;

    &--bottom {
      height: 0.5rem;
      width: 3rem;
      background-color: $color-light-blue;
    }

    .icon-button-block {
      float: right;
    }
  }

  .user-details-paginate {
    display: flex;
    margin-top: 14px;
    margin-left: 10px;
    align-items: center;
    width: 100%;

    .first-pg{
      svg{
        margin: 0;
      }
    }

    svg {
      margin: 0px 10px;
      font-size: 15px;
      cursor: pointer;
    }

    .input-box {
      display: flex;
      align-items: center;
      width: 134px;
      input {
        width: 80px;
        margin-right: 10px;
      }
    }
  }
}