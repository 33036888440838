@mixin clearFix {
  &::after {
    content: "";
    clear: both;
    display: table;
  }
}

@mixin BackgroundFillRight() {
  @for $i from 1 through 100 {
    $whitePercent: 100-$i;
    .background-fill-r-#{$i} {
      background: linear-gradient(
        to right,
        $color-new-red-light $i * 1%,
        white $i * 1%
      );

      &:hover {
        background: linear-gradient(
          to right,
          darken($color-new-red-light, 10%) $i * 1%,
          lightgrey $i * 1%
        );
      }
    }
  }
}

@mixin BackgroundFillLeft() {
  @for $i from 1 through 100 {
    $whitePercent: 100-$i;
    .background-fill-l-#{$i} {
      background: linear-gradient(
        to left,
        $color-new-green-light $i * 1%,
        white $i * 1%
      );

      &:hover {
        background: linear-gradient(
          to left,
          darken($color-new-green-light, 10%) $i * 1%,
          lightgrey $i * 1%
        );
      }
    }
  }
}
